var render = function () {
  var _vm$selectedCart$prod, _vm$selectedCart$prod2, _vm$selectedCart$prod3, _vm$selectedCart$prod4, _vm$selectedCart, _vm$selectedCart$prod5, _vm$selectedCart2, _vm$selectedCart2$pro, _vm$phone, _vm$formState, _vm$phone2, _vm$formState2, _vm$formState2$error, _vm$formState2$error$, _vm$formState2$error$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !!_vm.selectedCart.productService ? _c('CBox', {
    attrs: {
      "w": "100%",
      "bg": ['#F2F2F2', 'white'],
      "h": ['calc(100vh - 64px)', '100%']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Keranjang',
        href: '/cart'
      }, {
        label: 'Periksa Pesanan',
        isCurrent: true
      }]
    }
  })], 1), _c('CText', {
    attrs: {
      "d": ['none', 'block'],
      "text-align": "center",
      "font-family": "Roboto",
      "font-size": "20px",
      "font-weight": "700",
      "mb": "1rem",
      "color": "#333"
    }
  }, [_vm._v(" Periksa Pesanan ")]), _c('CBox', {
    attrs: {
      "d": "flex",
      "w": "100%",
      "flex-dir": ['column', 'row'],
      "px": "16px",
      "gap": "16px"
    }
  }, [_c('CBox', {
    attrs: {
      "bg": ['white', 'white'],
      "width": "100%",
      "p": ['16px', '30px'],
      "mt": "16px",
      "mb": ['316px', '0px'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px']
    }
  }, [_c('ElementCheckOrderInfoProgram', {
    attrs: {
      "photo-url": (_vm$selectedCart$prod = _vm.selectedCart.productService) === null || _vm$selectedCart$prod === void 0 ? void 0 : (_vm$selectedCart$prod2 = _vm$selectedCart$prod.product) === null || _vm$selectedCart$prod2 === void 0 ? void 0 : _vm$selectedCart$prod2.photoUrl,
      "name": (_vm$selectedCart$prod3 = _vm.selectedCart.productService) === null || _vm$selectedCart$prod3 === void 0 ? void 0 : (_vm$selectedCart$prod4 = _vm$selectedCart$prod3.product) === null || _vm$selectedCart$prod4 === void 0 ? void 0 : _vm$selectedCart$prod4.name,
      "sub-name": ((_vm$selectedCart = _vm.selectedCart) === null || _vm$selectedCart === void 0 ? void 0 : (_vm$selectedCart$prod5 = _vm$selectedCart.productService) === null || _vm$selectedCart$prod5 === void 0 ? void 0 : _vm$selectedCart$prod5.programsService) + ' ' + '(' + ((_vm$selectedCart2 = _vm.selectedCart) === null || _vm$selectedCart2 === void 0 ? void 0 : (_vm$selectedCart2$pro = _vm$selectedCart2.productService) === null || _vm$selectedCart2$pro === void 0 ? void 0 : _vm$selectedCart2$pro.duration) * _vm.selectedCart.quantity + ' Hari)',
      "route-change-program": _vm.routeChangeProgram,
      "price": _vm.price * _vm.selectedCart.quantity,
      "coupon-code": _vm.couponCode,
      "coupon-status": _vm.statusAppliedCoupon,
      "is-applying-coupon": _vm.isLoading.applyCoupon,
      "has-phone-number": _vm.hasPhoneNumberBefore,
      "is-valid-phone": ((_vm$phone = _vm.phone) === null || _vm$phone === void 0 ? void 0 : _vm$phone.length) >= 1 ? _vm.isValidPhone && ((_vm$formState = _vm.formState) === null || _vm$formState === void 0 ? void 0 : _vm$formState.success) : true,
      "info-message-phone": ((_vm$phone2 = _vm.phone) === null || _vm$phone2 === void 0 ? void 0 : _vm$phone2.length) >= 1 ? (_vm$formState2 = _vm.formState) === null || _vm$formState2 === void 0 ? void 0 : (_vm$formState2$error = _vm$formState2.error) === null || _vm$formState2$error === void 0 ? void 0 : (_vm$formState2$error$ = _vm$formState2$error.issues) === null || _vm$formState2$error$ === void 0 ? void 0 : (_vm$formState2$error$2 = _vm$formState2$error$[0]) === null || _vm$formState2$error$2 === void 0 ? void 0 : _vm$formState2$error$2.message : null,
      "phone": _vm.phone,
      "coupon-detail": _vm.couponDetail
    },
    on: {
      "set-coupon-code": _vm.handleChangeCoupon,
      "handle-click-check-coupon": _vm.handleCheckCoupon,
      "set-phone": function setPhone(e) {
        _vm.phone = e.target.value;
        _vm.isValidPhone = true;
      },
      "handle-more": _vm.handleMore
    }
  })], 1), _c('CBox', {
    attrs: {
      "mt": "16px",
      "bg": ['white', 'white'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "rounded-top": ['12px', '12px'],
      "rounded-bottom": ['none', '12px'],
      "position": ['fixed', 'unset'],
      "bottom": "0",
      "left": "0",
      "w": "100%",
      "p": ['16px', '30px'],
      "d": "flex",
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('CBox', {
    attrs: {
      "w": "100%"
    }
  }, [_c('ElementCheckOrderInfoPrice', {
    attrs: {
      "price": _vm.price * _vm.selectedCart.quantity,
      "discount": _vm.discount,
      "final-price": _vm.price * _vm.selectedCart.quantity - _vm.discount
    }
  }), _c('CButton', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "variant-color": "primary",
      "mt": "20px",
      "w": "100%",
      "rounded": "1000px",
      "size": "lg",
      "disabled": _vm.isLoading.checkout || !_vm.formState.success
    },
    on: {
      "click": _vm.handleCheckout
    }
  }, [_vm._v(" Lakukan Pembayaran ")])], 1)], 1)], 1), _c('ModalCouponInvalid', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalCouponInvalid
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  }), _c('ModalErrorProgramCannotPurchase', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchase
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  }), _c('ModalErrorProgramCannotPurchaseKonsultation', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }